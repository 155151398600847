import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { ILimbusCharacter } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import './employee.scss';
import './lc-character-dynamic.scss';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faChartLine,
  faSquare,
  faCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { LCAffinity } from '../modules/lc/common/components/lc-affinity';
import { LCRarity } from '../modules/lc/common/components/lc-rarity';
import { RatingBox } from '../modules/common/components/rating-box';
import { LCSpecialitiesArray } from '../modules/lc/model/lc-spec';
import lodash from 'lodash';
import { LimbusEgo } from '../modules/lc/common/components/lc-ego';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

const SpecialityItem = ({ speciality }) => {
  const specialityObj = lodash.find(LCSpecialitiesArray, {
    value: speciality
  });
  if (specialityObj) {
    const popoverSpecialities = (
      <Popover id="popover-specialities">
        <Popover.Header as="h3">{specialityObj.label}</Popover.Header>
        <Popover.Body>{specialityObj.description}</Popover.Body>
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="auto-start"
        overlay={popoverSpecialities}
      >
        <div className="employee-role">{specialityObj.label}</div>
      </OverlayTrigger>
    );
  } else {
    return <span>{speciality}</span>;
  }
};

interface ILimbusCharacterNodes {
  nodes: ILimbusCharacter[];
}

interface ILimbusCharacterEntry {
  currentUnit: ILimbusCharacterNodes;
}

interface IProps {
  data: ILimbusCharacterEntry;
}

const LimbusCharacterDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];
  const [currentTab, setCurrentTab] = useState('Info');

  const fullImage = character.imageFull
    ? getImage(character.imageFull.localFile.childImageSharp)
    : null;

  const image = character.imageSmall
    ? getImage(character.imageSmall.localFile.childImageSharp)
    : null;

  return (
    <DashboardLayout
      className={'generic-page character-page character-limbus'}
      game="lc"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/limbus-company/identities">Identities</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        {image && (
          <GatsbyImage
            className="disable-transition"
            image={image}
            alt={`${character.name}`}
          />
        )}
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <LCRarity rarity={character.rarity} />
            <LCAffinity
              affinity={character.affinityDetailed.affinity_1}
              value={character.affinityDetailed.affinity_1_v}
              hideLabel
            />
            <LCAffinity
              affinity={character.affinityDetailed.affinity_2}
              value={character.affinityDetailed.affinity_2_v}
              hideLabel
            />
            <LCAffinity
              affinity={character.affinityDetailed.affinity_3}
              value={character.affinityDetailed.affinity_3_v}
              hideLabel
            />
          </div>
        </div>
        <div className="character-ratings">
          {!character.isReviewPending ? (
            <>
              <RatingBox
                game="nikke"
                rating={character.ratings.pve}
                ratingName="PVE"
              />
            </>
          ) : (
            <p className="no-ratings">Ratings are pending review.</p>
          )}
        </div>
      </div>
      <Row className="intro-section">
        <Col xs={12} xl={12} xxl={12}>
          <div className={`content-header rarity-${character.rarity}`}>
            <FontAwesomeIcon icon={faSquare} width="18" /> Introduction
          </div>
          <div className="character-intro">
            <div className="combined">
              <h2>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                is a{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.rarity}
                </strong>{' '}
                rarity Identity from the Sinner{' '}
                <strong>{character.baseCharacter}</strong> that was added to the
                game on <strong>{character.releaseDate}</strong>. To learn more
                about{' '}
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                check the sections below. <strong>Use the tabs</strong> to
                quickly switch to the kind of information you're looking for -
                skills, affinities and more!
              </h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="fuse-ad-placeholder bigger" data-fuse="22844297232"></div>
      <div className="tabs">
        <div
          className={`single-tab rarity-${character.rarity} ${
            currentTab === 'Info' ? 'active' : ''
          }`}
          onClick={() => setCurrentTab('Info')}
        >
          <div className="top-icon">
            <FontAwesomeIcon icon={faCircleInfo} width="18" />
          </div>
          <p>Profile</p>
        </div>
        <div
          className={`single-tab rarity-${character.rarity} ${
            currentTab === 'Review' ? 'active' : ''
          }`}
          onClick={() => setCurrentTab('Review')}
        >
          <div className="top-icon">
            <FontAwesomeIcon icon={faBook} width="18" />
          </div>
          <p>Review</p>
        </div>
        <div
          className={`single-tab rarity-${character.rarity} ${
            currentTab === 'Gallery' ? 'active' : ''
          }`}
          onClick={() => setCurrentTab('Gallery')}
        >
          <div className="top-icon">
            <FontAwesomeIcon icon={faChartLine} width="18" />
          </div>
          <p>Gallery</p>
        </div>
      </div>
      <div className={`tab-inside ${currentTab === 'Info' ? 'active' : ''}`}>
        <div className={`mobile-header rarity-${character.rarity}}`}>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} width="18" />
            Profile
          </p>
        </div>
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Affinities
        </div>
        <Row xs={1} xxl={3} className="va-section">
          <Col>
            <div className="info-list-row">
              <div className="category">Affinity 1</div>
              <div className="details">
                {character.affinityDetailed && (
                  <>
                    <span
                      className={`limbus-affinity ${character.affinityDetailed.affinity_1}`}
                    >
                      {character.affinityDetailed.affinity_1}
                    </span>{' '}
                    x{character.affinityDetailed.affinity_1_v}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col>
            <div className="info-list-row">
              <div className="category">Affinity 2</div>
              <div className="details">
                {character.affinityDetailed && (
                  <>
                    <span
                      className={`limbus-affinity ${character.affinityDetailed.affinity_2}`}
                    >
                      {character.affinityDetailed.affinity_2}
                    </span>{' '}
                    x{character.affinityDetailed.affinity_2_v}
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col>
            <div className="info-list-row">
              <div className="category">Affinity 3</div>
              <div className="details">
                {character.affinityDetailed && (
                  <>
                    <span
                      className={`limbus-affinity ${character.affinityDetailed.affinity_3}`}
                    >
                      {character.affinityDetailed.affinity_3}
                    </span>{' '}
                    x{character.affinityDetailed.affinity_3_v}
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Specialities
        </div>
        <div className="specialities-list">
          {character.speciality ? (
            character.speciality.sort().map((speciality, idx) => {
              return <SpecialityItem key={idx} speciality={speciality} />;
            })
          ) : (
            <span className="no-spec">-</span>
          )}
        </div>
        <div className={`content-header  rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Skills
        </div>
        {character.skills ? (
          <>
            <div className={`skills-v2`}>
              <Row xs={1} xl={2} xxl={2}>
                {character.skills.map((skill, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${skill.type}`}>
                            {skill.isDefensive ? (
                              <>
                                <span className="skill-type pill">Defense</span>
                                <span className="skill-type pill">
                                  {skill.defensiveType}
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="skill-type pill">
                                  Skill {index + 1}
                                </span>
                              </>
                            )}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">{skill.name}</p>
                            <p className="skill-type">
                              {skill.type && (
                                <span
                                  className={`skill-type pill limbus-affinity-box ${skill.type}`}
                                >
                                  {skill.type}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className={`additional-information`}>
                          <p>
                            Attack Type:{' '}
                            {skill.attackType ? (
                              <span>{skill.attackType}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Skill Power:{' '}
                            {skill.skillPower ? (
                              <span>{skill.skillPower}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            No. of Coins:{' '}
                            {skill.numberOfCoins ? (
                              <span>{skill.numberOfCoins}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Coin Power:{' '}
                            {skill.coinPower ? (
                              <span>{skill.coinPower}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Offense Level:{' '}
                            {skill.offenseLevel ? (
                              <span>{skill.offenseLevel}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                          <p>
                            Atk Weight:{' '}
                            {skill.growth ? (
                              <span>{skill.growth}</span>
                            ) : (
                              <span>-</span>
                            )}
                          </p>
                        </div>
                        <div className={`skill-with-coloring`}>
                          {skill.skillEffect && (
                            <>{renderRichText(skill.skillEffect, options)}</>
                          )}
                        </div>
                        <div className="skill-description-full">
                          {skill.coinEffect && (
                            <>
                              {skill.coinEffect.level_1_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_1_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_1_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_2_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_2_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_2_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_3_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_3_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_3_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_4_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_4_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_4_d
                                    }}
                                  />
                                </div>
                              )}
                              {skill.coinEffect.level_5_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_5_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_5_d
                                    }}
                                  />
                                </div>
                              )}

                              {skill.coinEffect.level_6_l && (
                                <div className="coin">
                                  <span className="level-box">
                                    {skill.coinEffect.level_6_l}
                                  </span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: skill.coinEffect.level_6_d
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>skills aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        )}
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Passives
        </div>
        {character.passives ? (
          <>
            <div className={`skills-v2`}>
              <Row xs={1} xl={2} xxl={2}>
                {character.passives.map((skill, index) => {
                  return (
                    <Col key={index}>
                      <div className="box">
                        <div className="skill-header">
                          <div className={`skill-icon ${skill.type}`}>
                            {skill.passiveType}
                          </div>
                          <div className="skill-info">
                            <p className="skill-name">{skill.name}</p>
                            <p className="skill-type">
                              {skill.type && (
                                <span
                                  className={`skill-type pill limbus-affinity-box ${skill.type}`}
                                >
                                  x{skill.cost} {skill.type}{' '}
                                  {skill.category ? (
                                    <>&#91;{skill.category}&#93;</>
                                  ) : (
                                    <>&#91;?&#93;</>
                                  )}
                                </span>
                              )}
                              {skill.affinityType2 && (
                                <span
                                  className={`skill-type pill limbus-affinity-box ${skill.affinityType2}`}
                                >
                                  &nbsp;x{skill.cost2} {skill.affinityType2}{' '}
                                  {skill.category ? (
                                    <>&#91;{skill.category}&#93;</>
                                  ) : (
                                    <>&#91;?&#93;</>
                                  )}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className={`skill-with-coloring no-border`}>
                          {skill.description && (
                            <>{renderRichText(skill.description, options)}</>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>passives aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        )}
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Stats (At level 45)
        </div>
        {character.status ? (
          <>
            <Row xs={1} xl={2} xxl={2} className="info-list stats">
              <Col>
                <div className="stat-box">
                  <h5>Status</h5>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/stat_hp.png"
                        alt="Stat"
                      />
                      Max HP
                    </div>
                    <div className="details">
                      {character.status.hp_30 ? character.status.hp_30 : '-'}
                    </div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/stat_speed.png"
                        alt="Stat"
                      />
                      Speed Range
                    </div>
                    <div className="details">
                      {character.status.speed_30
                        ? character.status.speed_30
                        : '-'}
                    </div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/stat_def.png"
                        alt="Stat"
                      />
                      Defense
                    </div>
                    <div className="details">
                      {character.status.def_30 ? character.status.def_30 : '-'}
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="stat-box">
                  <h5>Resistances</h5>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/attackt_slash.png"
                        alt="Stat"
                      />
                      Slash RES
                    </div>
                    <div className="details">
                      {character.resistances.slash
                        ? character.resistances.slash
                        : '-'}
                    </div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/attackt_pierce.png"
                        alt="Stat"
                      />
                      Pierce RES
                    </div>
                    <div className="details">
                      {character.resistances.pierce
                        ? character.resistances.pierce
                        : '-'}
                    </div>
                  </div>
                  <div className="info-list-row">
                    <div className="category">
                      <StaticImage
                        src="../images/limbuscompany/icons/attackt_blunt.png"
                        alt="Stat"
                      />
                      Blunt RES
                    </div>
                    <div className="details">
                      {character.resistances.blunt
                        ? character.resistances.blunt
                        : '-'}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>stats aren't available yet.</strong> They will be added
              soon.
            </p>
          </div>
        )}
      </div>
      <div className={`tab-inside ${currentTab === 'Review' ? 'active' : ''}`}>
        <div className={`mobile-header rarity-${character.rarity}}`}>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} width="18" />
            Review
          </p>
        </div>
        {!character.isReviewPending ? (
          <div className="section-analysis">
            <div className="analysis">
              <div className={`content-header rarity-${character.rarity}`}>
                <FontAwesomeIcon icon={faSquare} width="18" /> Review
              </div>
              {character.review ? (
                <div>
                  <div className="review raw">
                    {character.review ? (
                      <>{renderRichText(character.review, options)}</>
                    ) : (
                      <p>Review is pending.</p>
                    )}
                  </div>
                  <div className={`content-header rarity-${character.rarity}`}>
                    <FontAwesomeIcon icon={faSquare} width="18" /> Pros & Cons
                  </div>
                  {character.pros && character.cons ? (
                    <Row className="pros-cons">
                      <Col sm="12" md="6">
                        <div className="box pros">
                          <h5>Pros</h5>
                          <hr />
                          <div className="raw list">
                            {renderRichText(character.pros, options)}
                          </div>
                        </div>
                      </Col>
                      <Col sm="12" md="6">
                        <div className="box cons">
                          <h5>Cons</h5>
                          <hr />
                          <div className="raw list">
                            {renderRichText(character.cons, options)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div className="info-box">
                      <p>
                        <strong className={`rarity-${character.rarity}`}>
                          {character.name}
                        </strong>{' '}
                        <strong>pros & cons aren't available yet.</strong> They
                        will be added soon.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="info-box">
                  <p>
                    <strong className={`rarity-${character.rarity}`}>
                      {character.name}
                    </strong>{' '}
                    <strong>review isn't available yet.</strong> It will be
                    added soon.
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className={`content-header rarity-${character.rarity}`}>
              <FontAwesomeIcon icon={faSquare} width="18" /> Review
            </div>
            <div className="info-box">
              <p>
                <strong className={`rarity-${character.rarity}`}>
                  {character.name}
                </strong>{' '}
                <strong>review isn't available yet.</strong> It will be added
                soon.
              </p>
            </div>
          </>
        )}
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Suggested EGO
        </div>
        {character.suggestedEgo ? (
          <>
            <p>
              In this section we have listed best E.G.O.s, one per their rank
              for the Identity - starting with Zayin from left.
            </p>
            <div className={`ego-container card-mode`}>
              {character.suggestedEgo.map((emp, index) => {
                return (
                  <span key={index}>
                    <Card className="avatar-card">
                      <LimbusEgo
                        slug={emp.slug}
                        mode="card"
                        showLabel
                        showTags
                        enablePopover
                      />
                    </Card>
                  </span>
                );
              })}
            </div>
          </>
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>suggested EGO aren't available yet.</strong> they will be
              added soon.
            </p>
          </div>
        )}
      </div>
      <div className={`tab-inside ${currentTab === 'Gallery' ? 'active' : ''}`}>
        <div className={`mobile-header rarity-${character.rarity}}`}>
          <p>
            <FontAwesomeIcon icon={faCircleInfo} width="18" />
            Gallery
          </p>
        </div>
        <div className={`content-header rarity-${character.rarity}`}>
          <FontAwesomeIcon icon={faSquare} width="18" /> Gallery
        </div>
        <h5>Full design (tier 3)</h5>
        {fullImage ? (
          <GatsbyImage image={fullImage} alt="" className="full-image" />
        ) : (
          <div className="info-box">
            <p>
              <strong className={`rarity-${character.rarity}`}>
                {character.name}
              </strong>{' '}
              <strong>full image isn't available yet.</strong> It will be added
              soon.
            </p>
          </div>
        )}
      </div>
      <div className="fuse-ad-placeholder bigger" data-fuse="22844297232"></div>
    </DashboardLayout>
  );
};

export default LimbusCharacterDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Limbus Company | Prydwen Institute'}
      description={
        character.name +
        ' is one of the Identities available in Limbus Company.'
      }
      image={character.imageFull ? character.imageFull : character.imageMid}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulLimbusCharacters(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...LimbusCharactersFieldsFragment
      }
    }
  }
`;
